import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import Loading from './components/Loading';

import App from './App';
import Admin from './admin';
import Auth from './auth';

const Login = React.lazy(() => import('./pages/login'));
const LoginForget = React.lazy(() => import('./pages/login/forget'));

const DailyWork = React.lazy(() => import("./pages/daily/work"));
const DailyWorkTeachRanking = React.lazy(() => import("./pages/daily/work/teachRanking"));
const DailyLessonRecord = React.lazy(() => import("./pages/daily/lessonRecord"));
const DailyMySale = React.lazy(() => import("./pages/daily/mySale"));
const DailyGymSale = React.lazy(() => import("./pages/daily/gymSale"));

const ForegroundSign = React.lazy(() => import("./pages/foreground/sign"));
const ForegroundSignList = React.lazy(() => import("./pages/foreground/sign/list"));
const ForegroundSignShoot = React.lazy(() => import("./pages/foreground/customer/shoot"));
const ForegroundCustomer = React.lazy(() => import("./pages/foreground/customer"));
const ForegroundCustomerAdd = React.lazy(() => import("./pages/foreground/customer/add"));
const ForegroundCustomerDetail = React.lazy(() => import("./pages/foreground/customer/detail"));
const ForegroundCustomerShoot = React.lazy(() => import("./pages/foreground/customer/shoot"));
const ForegroundCustomerMemberCard = React.lazy(() => import("./pages/foreground/customer/memberCard"));
const ForegroundCustomeFitnessTest = React.lazy(() => import("./pages/foreground/customer/fitnessTest"));
const ForegroundCustomerPayType = React.lazy(() => import("./pages/foreground/customer/payType"));
const ForegroundCustomerLessonPhoto = React.lazy(() => import("./pages/coach/lessonRecord/photo"));
const ForegroundContract = React.lazy(() => import("./pages/foreground/contract"));
const ForegroundMemberOperate = React.lazy(() => import("./pages/foreground/memberOperate"));
const ForegroundSignReport = React.lazy(() => import("./pages/foreground/signReport"));
const ForegroundComeReport = React.lazy(() => import("./pages/foreground/comeReport"));
const ForegroundDepositPay = React.lazy(() => import("./pages/foreground/depositPay"));

const MembershipMemberSale = React.lazy(() => import("./pages/membership/memberSale"));

const CoachMultipleLessonSchedule = React.lazy(() => import("./pages/coach/multipleLessonSchedule"));
const CoachMultipleLessonScheduleDetail = React.lazy(() => import("./pages/coach/multipleLessonSchedule/detail"));
const CoachMultipleLessonScheduleAddByWeek = React.lazy(() => import("./pages/coach/multipleLessonSchedule/addByWeek"));
const CoachMultipleLessonAgenda = React.lazy(() => import("./pages/coach/multipleLessonAgenda"));
const CoachMultipleLessonAgendaDetail = React.lazy(() => import("./pages/coach/multipleLessonAgenda/detail"));
const CoachBookLesson = React.lazy(() => import("./pages/coach/bookLesson"));
const CoachBookLessonAdd = React.lazy(() => import("./pages/coach/bookLesson/add"));
const CoachLessonRecord = React.lazy(() => import("./pages/coach/lessonRecord"));
const CoachLessonRecordPhoto = React.lazy(() => import("./pages/coach/lessonRecord/photo"));
const CoachCoachSetting = React.lazy(() => import("./pages/coach/coachSetting"));
const CoachCoachSettingDetail = React.lazy(() => import("./pages/coach/coachSetting/detail"));
const CoachCoachSettingStudent = React.lazy(() => import("./pages/coach/coachSetting/student"));
const CoachCoachSettingSimpleLessonType = React.lazy(() => import("./pages/coach/coachSetting/simpleLessonType"));
const CoachCoachSettingMultipleLessonType = React.lazy(() => import("./pages/coach/coachSetting/multipleLessonType"));
const CoachSaleReport = React.lazy(() => import("./pages/coach/saleReport"));
const CoachLessonReport = React.lazy(() => import("./pages/coach/lessonReport"));

const FinanceIncomeReport = React.lazy(() => import("./pages/finance/incomeReport"));
const FinanceIncomeDetail = React.lazy(() => import("./pages/finance/incomeDetail"));
const FinanceManageReport = React.lazy(() => import("./pages/finance/manageReport"));
const FinanceManageExport = React.lazy(() => import("./pages/finance/manageExport"));

const ConfigCardType = React.lazy(() => import("./pages/config/cardType"));
const ConfigCardTypeDetail = React.lazy(() => import("./pages/config/cardType/detail"));
const ConfigCardTypeSimpleLessonType = React.lazy(() => import("./pages/config/cardType/simpleLessonType"));
const ConfigCardTypeMultipleLessonType = React.lazy(() => import("./pages/config/cardType/multipleLessonType"));
const ConfigExpire = React.lazy(() => import("./pages/config/expire"));
const ConfigExpireDetail = React.lazy(() => import("./pages/config/expire/detail"));
const ConfigExpireAdd = React.lazy(() => import("./pages/config/expire/add"));
const ConfigSimpleLessonType = React.lazy(() => import("./pages/config/simpleLessonType"));
const ConfigSimpleLessonTypeDetail = React.lazy(() => import("./pages/config/simpleLessonType/detail"));
const ConfigSimpleLessonTypeCategory = React.lazy(() => import("./pages/config/simpleLessonType/category"));
const ConfigMultipleLessonType = React.lazy(() => import("./pages/config/multipleLessonType"));
const ConfigMultipleLessonTypeDetail = React.lazy(() => import("./pages/config/multipleLessonType/detail"));
const ConfigMultipleLessonTypeCategory = React.lazy(() => import("./pages/config/multipleLessonType/category"));
const ConfigMultipleLessonTypeCardType = React.lazy(() => import("./pages/config/multipleLessonType/cardType"));
const ConfigMultipleLessonTypeCoach = React.lazy(() => import("./pages/config/multipleLessonType/coach"));
const ConfigOrigin = React.lazy(() => import("./pages/config/origin"));
const ConfigEmployee = React.lazy(() => import("./pages/config/employee"));

const OperationGym = React.lazy(() => import("./pages/operation/gym"));
const OperationCoach = React.lazy(() => import("./pages/operation/coach"));
const OperationCoachDetail = React.lazy(() => import("./pages/operation/coach/detail"));
const OperationSuggestion = React.lazy(() => import("./pages/operation/suggestion"));
const OperationAppraise = React.lazy(() => import("./pages/operation/appraise"));
const OperationLottery = React.lazy(() => import("./pages/operation/lottery"));
const OperationLotteryDetail = React.lazy(() => import("./pages/operation/lottery/detail"));
const OperationLotteryUser = React.lazy(() => import("./pages/operation/lottery/user"));

const SettingAuthorize = React.lazy(() => import("./pages/setting/authorize"));
const SettingAuthorizeSuccess = React.lazy(() => import("./pages/setting/authorize/success"));
const SettingPosition = React.lazy(() => import("./pages/setting/position"));
const SettingPositionRole = React.lazy(() => import("./pages/setting/position/role"));
const SettingSms = React.lazy(() => import("./pages/setting/sms"));
const SettingSmsDetail = React.lazy(() => import("./pages/setting/sms/detail"));
const SettingGym = React.lazy(() => import("./pages/setting/gym"));
const SettingBackup = React.lazy(() => import("./pages/setting/backup"));
const SettingImport = React.lazy(() => import("./pages/setting/import"));
const SettingImportDetail = React.lazy(() => import("./pages/setting/import/detail"));
const SettingExport = React.lazy(() => import("./pages/setting/export"));
const SettingCanceledUser = React.lazy(() => import("./pages/setting/canceledUser"));

const SystemAuthorize = React.lazy(() => import("./pages/system/authorize"));
const SystemMiniProgram = React.lazy(() => import("./pages/system/miniProgram"));
const SystemGroup = React.lazy(() => import("./pages/system/group"));
const SystemGroupDetail = React.lazy(() => import("./pages/system/group/detail"));
const SystemSingle = React.lazy(() => import("./pages/system/single"));
const SystemSingleDetail = React.lazy(() => import("./pages/system/single/detail"));
const SystemSuggestion = React.lazy(() => import("./pages/system/suggestion"));
const SystemAppraise = React.lazy(() => import("./pages/system/appraise"));

export default class IRouter extends React.Component {
    render() {
        const session = JSON.parse(localStorage.getItem("session"));
        const Home = (session && session.menus) ? "/" + session.menus[0].children[0].key : "/login";

        return (
            <Router>
                <ConfigProvider locale={zhCN}>
                    <Suspense fallback={<Loading />}>
                        <App>
                            <Auth />
                            <Switch>
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/login/forget" component={LoginForget} />
                                <Route path='/' render={() =>
                                    <Admin>
                                        <Switch>
                                            <Route exact path="/daily/work" component={DailyWork} />
                                            <Route exact path="/daily/work/teachRanking" component={DailyWorkTeachRanking} />
                                            <Route exact path="/daily/lessonRecord" component={DailyLessonRecord} />
                                            <Route exact path="/daily/mySale" component={DailyMySale} />
                                            <Route exact path="/daily/gymSale" component={DailyGymSale} />

                                            <Route exact path="/foreground/sign" component={ForegroundSign} />
                                            <Route exact path="/foreground/sign/list" component={ForegroundSignList} />
                                            <Route exact path="/foreground/sign/shoot" component={ForegroundSignShoot} />
                                            <Route exact path="/foreground/customer" component={ForegroundCustomer} />
                                            <Route exact path="/foreground/customer/contract" component={ForegroundCustomerMemberCard} />
                                            <Route exact path="/foreground/customer/add" component={ForegroundCustomerAdd} />
                                            <Route exact path="/foreground/customer/edit" component={ForegroundCustomerDetail} />
                                            <Route exact path="/foreground/customer/shoot" component={ForegroundCustomerShoot} />
                                            <Route exact path="/foreground/customer/memberCard/add" component={ForegroundCustomerMemberCard} />
                                            <Route exact path="/foreground/customer/memberCard/edit" component={ForegroundCustomerMemberCard} />
                                            <Route exact path="/foreground/customer/fitnessTest/add" component={ForegroundCustomeFitnessTest} />
                                            <Route exact path="/foreground/customer/fitnessTest/edit" component={ForegroundCustomeFitnessTest} />
                                            <Route exact path="/foreground/customer/payType" component={ForegroundCustomerPayType} />
                                            <Route exact path="/foreground/customer/lessonPhoto" component={ForegroundCustomerLessonPhoto} />
                                            <Route exact path="/foreground/contract" component={ForegroundContract} />
                                            <Route exact path="/foreground/memberOperate" component={ForegroundMemberOperate} />
                                            <Route exact path="/foreground/signReport" component={ForegroundSignReport} />
                                            <Route exact path="/foreground/comeReport" component={ForegroundComeReport} />
                                            <Route exact path="/foreground/depositPay" component={ForegroundDepositPay} />

                                            <Route exact path="/membership/memberSale" component={MembershipMemberSale} />

                                            <Route exact path="/coach/multipleLessonSchedule" component={CoachMultipleLessonSchedule} />
                                            <Route exact path="/coach/multipleLessonSchedule/add" component={CoachMultipleLessonScheduleDetail} />
                                            <Route exact path="/coach/multipleLessonSchedule/edit" component={CoachMultipleLessonScheduleDetail} />
                                            <Route exact path="/coach/multipleLessonSchedule/addByWeek" component={CoachMultipleLessonScheduleAddByWeek} />
                                            <Route exact path="/coach/multipleLessonAgenda" component={CoachMultipleLessonAgenda} />
                                            <Route exact path="/coach/multipleLessonAgenda/edit" component={CoachMultipleLessonAgendaDetail} />
                                            <Route exact path="/coach/bookLesson" component={CoachBookLesson} />
                                            <Route exact path="/coach/bookLesson/add" component={CoachBookLessonAdd} />
                                            <Route exact path="/coach/lessonRecord" component={CoachLessonRecord} />
                                            <Route exact path="/coach/lessonRecord/photo" component={CoachLessonRecordPhoto} />
                                            <Route exact path="/coach/coachSetting" component={CoachCoachSetting} />
                                            <Route exact path="/coach/coachSetting/edit" component={CoachCoachSettingDetail} />
                                            <Route exact path="/coach/coachSetting/student" component={CoachCoachSettingStudent} />
                                            <Route exact path="/coach/coachSetting/simpleLessonType" component={CoachCoachSettingSimpleLessonType} />
                                            <Route exact path="/coach/coachSetting/multipleLessonType" component={CoachCoachSettingMultipleLessonType} />
                                            <Route exact path="/coach/saleReport" component={CoachSaleReport} />
                                            <Route exact path="/coach/lessonReport" component={CoachLessonReport} />

                                            <Route exact path="/finance/incomeReport" component={FinanceIncomeReport} />
                                            <Route exact path="/finance/incomeDetail" component={FinanceIncomeDetail} />
                                            <Route exact path="/finance/manageReport" component={FinanceManageReport} />
                                            <Route exact path="/finance/manageExport" component={FinanceManageExport} />

                                            <Route exact path="/config/cardType" component={ConfigCardType} />
                                            <Route exact path="/config/cardType/add" component={ConfigCardTypeDetail} />
                                            <Route exact path="/config/cardType/edit" component={ConfigCardTypeDetail} />
                                            <Route exact path="/config/cardType/simpleLessonType" component={ConfigCardTypeSimpleLessonType} />
                                            <Route exact path="/config/cardType/multipleLessonType" component={ConfigCardTypeMultipleLessonType} />
                                            <Route exact path="/config/expire" component={ConfigExpire} />
                                            <Route exact path="/config/expire/detail" component={ConfigExpireDetail} />
                                            <Route exact path="/config/expire/add" component={ConfigExpireAdd} />
                                            <Route exact path="/config/simpleLessonType" component={ConfigSimpleLessonType} />
                                            <Route exact path="/config/simpleLessonType/category" component={ConfigSimpleLessonTypeCategory} />
                                            <Route exact path="/config/simpleLessonType/cardType" component={ConfigMultipleLessonTypeCardType} />
                                            <Route exact path="/config/simpleLessonType/coach" component={ConfigMultipleLessonTypeCoach} />
                                            <Route exact path="/config/simpleLessonType/add" component={ConfigSimpleLessonTypeDetail} />
                                            <Route exact path="/config/simpleLessonType/edit" component={ConfigSimpleLessonTypeDetail} />
                                            <Route exact path="/config/multipleLessonType" component={ConfigMultipleLessonType} />
                                            <Route exact path="/config/multipleLessonType/category" component={ConfigMultipleLessonTypeCategory} />
                                            <Route exact path="/config/multipleLessonType/add" component={ConfigMultipleLessonTypeDetail} />
                                            <Route exact path="/config/multipleLessonType/edit" component={ConfigMultipleLessonTypeDetail} />
                                            <Route exact path="/config/multipleLessonType/cardType" component={ConfigMultipleLessonTypeCardType} />
                                            <Route exact path="/config/multipleLessonType/coach" component={ConfigMultipleLessonTypeCoach} />
                                            <Route exact path="/config/origin" component={ConfigOrigin} />
                                            <Route exact path="/config/employee" component={ConfigEmployee} />

                                            <Route exact path="/operation/gym" component={OperationGym} />
                                            <Route exact path="/operation/coach" component={OperationCoach} />
                                            <Route exact path="/operation/coach/edit" component={OperationCoachDetail} />
                                            <Route exact path="/operation/suggestion" component={OperationSuggestion} />
                                            <Route exact path="/operation/appraise" component={OperationAppraise} />
                                            <Route exact path="/operation/lottery" component={OperationLottery} />
                                            <Route exact path="/operation/lottery/add" component={OperationLotteryDetail} />
                                            <Route exact path="/operation/lottery/edit" component={OperationLotteryDetail} />
                                            <Route exact path="/operation/lottery/user" component={OperationLotteryUser} />

                                            <Route exact path="/setting/authorize" component={SettingAuthorize} />
                                            <Route exact path="/setting/authorize/success" component={SettingAuthorizeSuccess} />
                                            <Route exact path="/setting/position" component={SettingPosition} />
                                            <Route exact path="/setting/position/role" component={SettingPositionRole} />
                                            <Route exact path="/setting/sms" component={SettingSms} />
                                            <Route exact path="/setting/sms/detail" component={SettingSmsDetail} />
                                            <Route exact path="/setting/gym" component={SettingGym} />
                                            <Route exact path="/setting/backup" component={SettingBackup} />
                                            <Route exact path="/setting/import" component={SettingImport} />
                                            <Route exact path="/setting/import/detail" component={SettingImportDetail} />
                                            <Route exact path="/setting/export" component={SettingExport} />
                                            <Route exact path="/setting/canceledUser" component={SettingCanceledUser} />

                                            <Route exact path="/system/authorize" component={SystemAuthorize} />
                                            <Route exact path="/system/miniProgram" component={SystemMiniProgram} />
                                            <Route exact path="/system/group" component={SystemGroup} />
                                            <Route exact path="/system/group/detail" component={SystemGroupDetail} />
                                            <Route exact path="/system/single" component={SystemSingle} />
                                            <Route exact path="/system/single/add" component={SystemSingleDetail} />
                                            <Route exact path="/system/single/edit" component={SystemSingleDetail} />
                                            <Route exact path="/system/suggestion" component={SystemSuggestion} />
                                            <Route exact path="/system/appraise" component={SystemAppraise} />

                                            <Redirect to={Home} />
                                        </Switch>
                                    </Admin>
                                } />
                            </Switch>
                        </App>
                    </Suspense>
                </ConfigProvider>
            </Router>
        );
    }
}