import React from "react";

import {
  HomeOutlined,
  BankOutlined,
  ProfileOutlined,
  SettingOutlined,
  DesktopOutlined,
  CreditCardOutlined,
  CloudOutlined,
  UserOutlined,
  ToolOutlined,
} from "@ant-design/icons";

const menus = [
  {
    title: "日常管理",
    key: "daily",
    icon: <HomeOutlined />,
    children: [
      { title: "我的工作", key: "daily/work" },
      { title: "我的上课", key: "daily/lessonRecord" },
      { title: "我的业绩", key: "daily/mySale" },
      { title: "场馆业绩", key: "daily/gymSale" },
    ],
  },
  {
    title: "前台运营",
    key: "foreground",
    icon: <DesktopOutlined />,
    children: [
      { title: "入场签到", key: "foreground/sign" },
      { title: "客户管理", key: "foreground/customer" },
      { title: "合同列表", key: "foreground/contract" },
      { title: "会员操作", key: "foreground/memberOperate" },
      { title: "入场报表", key: "foreground/signReport" },
      { title: "出勤报表", key: "foreground/comeReport" },
      { title: "储值消费", key: "foreground/depositPay" },
    ],
  },
  {
    title: "会籍管理",
    key: "membership",
    icon: <CreditCardOutlined />,
    children: [{ title: "销售业绩", key: "membership/memberSale" }],
  },
  {
    title: "私教管理",
    key: "coach",
    icon: <UserOutlined />,
    children: [
      { title: "团课排期", key: "coach/multipleLessonSchedule" },
      { title: "团课日程", key: "coach/multipleLessonAgenda" },
      { title: "约课管理", key: "coach/bookLesson" },
      { title: "上课记录", key: "coach/lessonRecord" },
      { title: "教练设置", key: "coach/coachSetting" },
      { title: "销售业绩", key: "coach/saleReport" },
      { title: "上课业绩", key: "coach/lessonReport" },
    ],
  },
  {
    title: "财务管理",
    key: "finance",
    icon: <BankOutlined />,
    children: [
      { title: "收入统计", key: "finance/incomeReport" },
      { title: "收入详情", key: "finance/incomeDetail" },
      { title: "经营统计", key: "finance/manageReport" },
    ],
  },
  {
    title: "日常配置",
    key: "config",
    icon: <ProfileOutlined />,
    children: [
      { title: "合同卡种", key: "config/cardType" },
      { title: "有效期管理", key: "config/expire" },
      { title: "私教课种", key: "config/simpleLessonType" },
      { title: "团课课种", key: "config/multipleLessonType" },
      { title: "来源管理", key: "config/origin" },
      { title: "员工管理", key: "config/employee" },
    ],
  },
  {
    title: "在线运营",
    key: "operation",
    icon: <CloudOutlined />,
    children: [
      { title: "场馆在线", key: "operation/gym" },
      { title: "教练在线", key: "operation/coach" },
      { title: "意见反馈", key: "operation/suggestion" },
      { title: "评价列表", key: "operation/appraise" },
      { title: "抽奖活动", key: "operation/lottery" },
    ],
  },
  {
    title: "系统配置",
    key: "setting",
    icon: <SettingOutlined />,
    children: [
      { title: "职位管理", key: "setting/position" },
      { title: "短信配置", key: "setting/sms" },
      { title: "场馆配置", key: "setting/gym" },
      { title: "安全备份", key: "setting/backup" },
      { title: "数据导出", key: "setting/export" },
      { title: "数据导入", key: "setting/import" },
      { title: "注销客户", key: "setting/canceledUser" },
      { title: "开发授权", key: "setting/authorize" },
    ],
  },
  {
    title: "嘉衡管理",
    key: "system",
    icon: <ToolOutlined />,
    children: [
      { title: "授权客户", key: "system/authorize" },
      { title: "小程序管理", key: "system/miniProgram" },
      { title: "集团列表", key: "system/group" },
      { title: "单店列表", key: "system/single" },
      { title: "意见反馈", key: "system/suggestion" },
      { title: "评价管理", key: "system/appraise" },
    ],
  },
];
export default menus;
