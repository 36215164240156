import React from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { switchMenu } from '@redux/action';
import allMenus from '@config/menus';
import allTitles from '@config/titles';
import { Layout, Menu, Dropdown } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import axios from '@axios';
import './index.scss';

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

@connect(
    state => state,
    { switchMenu }
)
class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentKey: '',     // 当前菜单的key
            currentTitle: '',   // 当前页面的标题
            menuTreeNode: null, // 菜单树形节点
            myGyms: [],         // 健身房列表
            companyLogo: '',    // 公司logo
            companyName: '',    // 公司名称
            menus: []           // 菜单列表
        };
        this.selectGym = this.selectGym.bind(this);
    }

    componentDidMount() {
        this.intitialComponent();
        this.getMyGyms();
        this.props.history.listen((location) => {
            this.setCurrentKey(location);
            this.setCurrentTitle(location);
        })
    }

    intitialComponent() {
        const session = localStorage.session ? JSON.parse(localStorage.session) : null;
        const menus = session && session.menus ? session.menus : allMenus;

        menus.map((item) => {
            allMenus.forEach((menuItem) => {
                if (item.key === menuItem.key) {
                    item.icon = menuItem.icon;
                }
            });
            return item;
        });

        this.setState({
            companyLogo: session ? session.gymLogoUrl : '',
            companyName: session ? session.gymName : '',
            menuTreeNode: this.renderMenu(menus),
            menus
        });
        this.setCurrentKey(window.location);
        this.setCurrentTitle(window.location);
    }

    async getMyGyms() {
        const myGyms = await axios.ajax({
            url: "employee/listMyGyms"
        });
        this.setState({ myGyms });
    }

    renderMenu = (data) => {
        return data.map(item => {
            if (item.children) {
                return (
                    <SubMenu
                        title={item.title}
                        key={item.key}
                        icon={item.icon}
                    >
                        {this.renderMenu(item.children)}
                    </SubMenu>
                )
            }
            return <Menu.Item
                key={item.key}
                icon={item.icon}
            >
                <NavLink to={`/${item.key}`} replace>
                    {item.title}
                </NavLink>
            </Menu.Item>
        });
    }

    setCurrentKey = (location) => {
        let currentPath = location.pathname.replace(/\?.*$/g, '');  // 取得当前路径
        let mainMenuKey = currentPath.match(/^\/[a-zA-Z]*/g)[0].slice(1); // 根据当前路径取得一级菜单的key
        let currentKey = "";

        // 根据路由设置currentKey
        allMenus.forEach((mainItem) => {
            // 根据mainMenuKey在menus中找到相应的对象
            if (mainItem.key === mainMenuKey) {
                // 如果该目录下有二级菜单，就用二级目录的key设置currentKey
                if (mainItem.children) {
                    // 根据当前路径取得二级目录的key，然后设置currentKey
                    mainItem.children.forEach((subItem) => {
                        if (currentPath.match(subItem.key)) {
                            currentKey = currentPath.match(subItem.key)[0];
                        }
                    });
                } else {
                    currentKey = mainMenuKey;
                }
            }
        });
        this.setState({ currentKey });
    }

    setCurrentTitle = (location) => {
        let currentPath = location.pathname.replace(/#|\?.*$/g, '');  // 取得当前路径
        let mainMenuKey = currentPath.match(/^\/[a-zA-Z]*/g)[0].slice(1); // 根据当前路径取得一级菜单的key
        let currentTitle = "";

        // 根据路由渲染标题
        allTitles.forEach((mainItem) => {
            // 如果当前路径就是mainItem.key，那么直接返回mainItem.title
            if (mainItem.key === currentPath) {
                currentTitle = mainItem.title;
            }
            // 如果当前路径不是mainItem.key，那么根据mainMenuKey在titleList中找到相应的对象
            else if (mainItem.key === mainMenuKey) {
                // 如果该目录下有二级菜单，就用二级目录的title设置currentTitle
                if (mainItem.hasOwnProperty('children')) {
                    // 根据当前路径取得二级目录的title，然后设置currentTitle
                    mainItem.children.forEach((subItem) => {
                        if (currentPath.indexOf(subItem.key) !== -1) {
                            currentTitle = subItem.title;
                        }
                    })
                } else {
                    currentTitle = mainItem.title;
                }
            }
        });
        // 如果标题产生了变化，那么更新currentTitle，同时分发redux的切换菜单事件
        if (currentTitle !== this.state.currentTitle) {
            this.setState({ currentTitle });
            this.props.switchMenu(currentTitle);
        }
    }

    // 为了防止切换到login页面时以下警告的发生
    // Can't call setState (or forceUpdate) on an unmounted component
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }

    async selectGym(e) {
        const session = await axios.ajax({
            url: "employee/gymSelect",
            params: {
                gymId: e.key
            }
        });
        localStorage.session = JSON.stringify(session);
        this.intitialComponent();

        const HomePage = "/" + session.menus[0].children[0].key;
        this.props.history.push(HomePage);
        window.location.reload();
    }

    render() {
        const { currentKey, menuTreeNode, myGyms, companyLogo, companyName } = this.state;

        return (
            <Sider className="sidebar">
                <div className="logo">
                    {companyLogo && <img src={`${companyLogo}?x-oss-process=image/resize,w_600`} alt="公司LOGO" />}
                    {myGyms.length > 1 ? <Dropdown
                        overlay={<Menu onClick={this.selectGym}>
                            {myGyms.map(item => {
                                return <Menu.Item key={item.sysGymDO.id} value={item.sysGymDO.id}>{item.sysGymDO.gymName}</Menu.Item>
                            })}
                        </Menu>}
                        placement="bottomRight"
                        trigger={["click"]}
                    >
                        <div className="pt-2" style={{ cursor: "pointer" }}>
                            <span>{companyName}</span>
                            <span className="text-primary">
                                <SwapOutlined className="ml-1" />
                                <span>切换</span>
                            </span>
                        </div>
                    </Dropdown>
                        : <span>{companyName}</span>}
                </div>
                <Menu
                    theme="dark"
                    selectedKeys={[currentKey]}
                    mode="inline"
                >
                    {menuTreeNode}
                </Menu>
            </Sider>
        );
    }
}

export default withRouter(Sidebar);