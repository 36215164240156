import React from 'react';
import axios from 'axios';
import { Modal, Message } from 'antd';
import { API_URL } from '@config/url';

function ajax({ url, params, headers, method, isShowLoading = true }) {
    let loading;
    // 显示加载中信息
    if (isShowLoading) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = 'block';
    }

    return new Promise(async function (resolve, reject) {
        const session = JSON.parse(localStorage.getItem("session"));
        const response = await axios({
            url,
            method: method || 'post',
            baseURL: API_URL,
            timeout: 60000,
            data: params || {},
            headers: headers || { Authorization: session && session.token }
        });
        // 隐藏加载中信息
        if (isShowLoading) {
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'none';
        }
        // 处理返回结果
        if (response && response.status === 200) {
            const { data } = response;
            if (data.success) {
                resolve(data.data);
            } else {
                const { errorCode, errorMsg } = data;
                if (errorCode === '401' || errorCode === 'USER_NOT_LOGIN') {
                    console.log(errorMsg);
                } else {
                    // 错误提示
                    let content = null;
                    if (errorMsg) {
                        content = errorMsg.split("\n").map((item, index) => {
                            return <p key={index}>{item}</p>
                        });
                    }
                    Modal.info({
                        title: "提示",
                        content: <div style={{ maxHeight: "70vh", overflow: "auto" }}>{content}</div>
                    });
                }
                reject(data);
            }
        } else {
            console.log(response && response.data);
            Message.error("网络请求失败，请检查网络连接！");
        }
    });
}

export default { ajax }
