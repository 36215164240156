const titles = [
  {
    title: "日常管理",
    key: "daily",
    children: [
      { title: "我的工作", key: "daily/work" },
      { title: "消课排名", key: "daily/work/teachRanking" },
      { title: "我的上课", key: "daily/lessonRecord" },
      { title: "我的业绩", key: "daily/mySale" },
      { title: "场馆业绩", key: "daily/gymSale" },
    ],
  },
  {
    title: "前台运营",
    key: "foreground",
    children: [
      { title: "入场签到", key: "foreground/sign" },
      { title: "签到列表", key: "foreground/sign/list" },
      { title: "拍照上传头像", key: "foreground/sign/shoot" },
      { title: "客户管理", key: "foreground/customer" },
      { title: "录入合同", key: "foreground/customer/contract" },
      { title: "录入会员", key: "foreground/customer/add" },
      { title: "客户详情", key: "foreground/customer/edit" },
      { title: "拍照上传头像", key: "foreground/customer/shoot" },
      { title: "新增会员卡", key: "foreground/customer/memberCard/add" },
      { title: "会员卡详情", key: "foreground/customer/memberCard/edit" },
      { title: "支付方式", key: "foreground/customer/payType" },
      { title: "上课照片", key: "foreground/customer/lessonPhoto" },
      { title: "合同列表", key: "foreground/contract" },
      { title: "会员操作", key: "foreground/memberOperate" },
      { title: "入场报表", key: "foreground/signReport" },
      { title: "出勤报表", key: "foreground/comeReport" },
      { title: "储值消费", key: "foreground/depositPay" },
    ],
  },
  {
    title: "会籍管理",
    key: "membership",
    children: [{ title: "销售业绩", key: "membership/memberSale" }],
  },
  {
    title: "私教管理",
    key: "coach",
    children: [
      { title: "团课排期", key: "coach/multipleLessonSchedule" },
      { title: "新增团课排期", key: "coach/multipleLessonSchedule/add" },
      { title: "新增团课排期", key: "coach/multipleLessonSchedule/addByWeek" },
      { title: "团课排期详情", key: "coach/multipleLessonSchedule/edit" },
      { title: "团课日程", key: "coach/multipleLessonAgenda" },
      { title: "新增团课日程", key: "coach/multipleLessonAgenda/add" },
      { title: "团课日程详情", key: "coach/multipleLessonAgenda/edit" },
      { title: "约课管理", key: "coach/bookLesson" },
      { title: "新增预约", key: "coach/bookLesson/add" },
      { title: "上课记录", key: "coach/lessonRecord" },
      { title: "上课照片", key: "coach/lessonRecord/photo" },
      { title: "教练设置", key: "coach/coachSetting" },
      { title: "教练详情", key: "coach/coachSetting/edit" },
      { title: "绑定会员", key: "coach/coachSetting/student" },
      { title: "绑定私教课种", key: "coach/coachSetting/simpleLessonType" },
      { title: "绑定团课课种", key: "coach/coachSetting/multipleLessonType" },
      { title: "销售业绩", key: "coach/saleReport" },
      { title: "上课业绩", key: "coach/lessonReport" },
    ],
  },
  {
    title: "财务管理",
    key: "finance",
    children: [
      { title: "收入统计", key: "finance/incomeReport" },
      { title: "收入详情", key: "finance/incomeDetail" },
      { title: "经营统计", key: "finance/manageReport" },
      { title: "资料下载", key: "finance/manageExport" },
    ],
  },
  {
    title: "日常配置",
    key: "config",
    children: [
      { title: "合同卡种", key: "config/cardType" },
      { title: "新增合同卡种", key: "config/cardType/add" },
      { title: "合同卡种详情", key: "config/cardType/edit" },
      { title: "绑定私教课种", key: "config/cardType/simpleLessonType" },
      { title: "绑定团课课种", key: "config/cardType/multipleLessonType" },
      { title: "有效期管理", key: "config/expire" },
      { title: "批量修改任务详情", key: "config/expire/detail" },
      { title: "新增批量修改任务", key: "config/expire/add" },
      { title: "私教课种", key: "config/simpleLessonType" },
      { title: "新增私教课种", key: "config/simpleLessonType/add" },
      { title: "私教课种详情", key: "config/simpleLessonType/edit" },
      { title: "私教课种类型", key: "config/simpleLessonType/category" },
      { title: "绑定合同卡种", key: "config/simpleLessonType/cardType" },
      { title: "绑定教练", key: "config/simpleLessonType/coach" },
      { title: "团课课种", key: "config/multipleLessonType" },
      { title: "新增团课课种", key: "config/multipleLessonType/add" },
      { title: "团课课种详情", key: "config/multipleLessonType/edit" },
      { title: "团课课种类型", key: "config/multipleLessonType/category" },
      { title: "绑定合同卡种", key: "config/multipleLessonType/cardType" },
      { title: "绑定教练", key: "config/multipleLessonType/coach" },
      { title: "来源管理", key: "config/origin" },
      { title: "员工管理", key: "config/employee" },
    ],
  },
  {
    title: "在线运营",
    key: "operation",
    children: [
      { title: "场馆在线", key: "operation/gym" },
      { title: "教练在线", key: "operation/coach" },
      { title: "教练详情", key: "operation/coach/edit" },
      { title: "老板信箱", key: "operation/suggestion" },
      { title: "评价列表", key: "operation/appraise" },
      { title: "抽奖活动", key: "operation/lottery" },
      { title: "新增抽奖活动", key: "operation/lottery/add" },
      { title: "编辑抽奖活动", key: "operation/lottery/edit" },
      { title: "参与会员", key: "operation/lottery/user" },
    ],
  },
  {
    title: "系统配置",
    key: "setting",
    children: [
      { title: "职位管理", key: "setting/position" },
      { title: "短信配置", key: "setting/sms" },
      { title: "短信发送详情", key: "setting/sms/detail" },
      { title: "绑定角色", key: "setting/position/role" },
      { title: "场馆配置", key: "setting/gym" },
      { title: "安全备份", key: "setting/backup" },
      { title: "数据导出", key: "setting/export" },
      { title: "数据导入", key: "setting/import" },
      { title: "导入详情", key: "setting/import/detail" },
      { title: "注销客户", key: "setting/canceledUser" },
      { title: "开发授权", key: "setting/authorize" },
    ],
  },
  {
    title: "嘉衡管理",
    key: "system",
    children: [
      { title: "授权客户", key: "system/authorize" },
      { title: "小程序管理", key: "system/miniProgram" },
      { title: "集团列表", key: "system/group" },
      { title: "集团详情", key: "system/group/detail" },
      { title: "单店列表", key: "system/single" },
      { title: "新增单店", key: "system/single/add" },
      { title: "单店详情", key: "system/single/edit" },
      { title: "意见反馈", key: "system/suggestion" },
      { title: "评价管理", key: "system/appraise" },
    ],
  },
];
export default titles;
