import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

class Auth extends React.Component {
    componentDidMount() {
        // 响应拦截
        axios.interceptors.response.use((response) => {
            if (response.data.errorCode === '401' || response.data.errorCode === 'USER_NOT_LOGIN') {
                localStorage.removeItem('session');

                // 保存Logout前的路径信息到localStorage中
                // login页面的路径是不需要保存的，否则会覆盖之前的有效路径信息
                const url = window.location.pathname + window.location.search;
                if (url !== '/login') {
                    localStorage.urlAfterLogin = JSON.stringify(url);
                }
                this.props.history.push('/login');
            }
            return response;
        }, error => { });
    }
    render() {
        return null;
    }
}

export default withRouter(Auth);