import React from 'react';
import { Layout } from 'antd';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
const { Content } = Layout;

export default class Admin extends React.Component {
    render() {
        return (
            <Layout>
                <Sidebar />
                <Layout style={{ marginLeft: 200, minHeight: "100vh" }}>
                    <Header />
                    <Content style={{ margin: '12px 12px 0', overflow: 'initial' }}>
                        {this.props.children}
                    </Content>
                    <Layout.Footer>
                        <Footer textStyle={{ color: "#666" }} />
                    </Layout.Footer>
                </Layout>
            </Layout>
        );
    }
}