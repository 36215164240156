import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal, Button, Dropdown, Menu, message, Form, Input } from 'antd';
import { DownOutlined, EditOutlined, LogoutOutlined, LeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import axios from '@axios';
import menus from '@config/menus';
import moment from 'moment';

@connect(
    state => ({
        menuName: state.menuName
    })
)
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',       // 用户名
            sysTime: '',        // 系统时间
            showReturn: false,  // 是否显示返回按钮
            showModal: false,   // 是否显示模态框
        }
        this.modalForm = React.createRef();
        this.confirmModal = this.confirmModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.setUser();
        this.setTime();
        this.setReturn(window.location);
        this.props.history.listen((location) => {
            this.setReturn(location);
        });
    }

    // 为了防止切换到login页面时以下警告的发生
    // Can't call setState (or forceUpdate) on an unmounted component
    componentWillUnmount = () => {
        this.setState = (state, callback) => { return };
    }

    setUser() {
        this.setState({
            userName: localStorage.session ? JSON.parse(localStorage.session).realName : ""
        });
    }

    setTime() {
        this.setState({
            sysTime: moment(new Date().getTime()).format('YYYY/MM/DD HH:mm')
        });
        setInterval(() => {
            this.setState({
                sysTime: moment(new Date().getTime()).format('YYYY/MM/DD HH:mm')
            });
        }, 1000)
    }

    setReturn = (location) => {
        // 根据路由判断是否需要显示返回按钮
        let currentPath = location.pathname.replace(/\?.*$/g, '');   // 取得当前路径
        let currentKey = currentPath.slice(1);
        let mainMenuKey = currentKey.match(/[a-zA-Z]*/g)[0];       // 根据当前路径取得一级菜单的key
        let showReturn = true;

        menus.forEach((mainItem) => {
            // 根据mainMenuKey在menu中找到相应的对象
            if (mainItem.key === mainMenuKey) {
                // 如果该目录下有二级菜单，就判断当前页是否是二级菜单的主页
                if (mainItem.hasOwnProperty("children")) {
                    // 当前页是二级菜单的主页，就不显示返回按钮
                    // 当前页不是二级菜单的主页,比如是二级菜单的详情页，就显示返回按钮
                    mainItem.children.forEach((subItem) => {
                        if (currentKey === subItem.key) {
                            showReturn = false;
                        }
                    });
                }
                // 如果该目录下没有二级菜单，就判断当前页是否是主页
                else {
                    if (currentKey === mainMenuKey) {
                        showReturn = false;
                    } else {
                        showReturn = true;
                    }
                }
            }
        });
        this.setState({ showReturn });
    }

    clickUserSetting = (e) => {
        switch (e.key) {
            case 'item_0':
                this.setState({ showModal: true });
                break;
            case 'item_1':
                this.logout();
                break;
            default:
                break;
        }
    }

    async confirmModal() {
        await this.modalForm.current.validateFields();

        try {
            await axios.ajax({
                url: `/user/updatePassword`,
                params: this.modalForm.current.getFieldsValue()
            });
            message.success('修改成功');
        } finally {
            this.cancelModal();
        }
    }

    cancelModal() {
        this.modalForm.current.resetFields();
        this.setState({ showModal: false });
    }

    logout() {
        const modal = Modal.confirm({
            title: '确定退出吗?',
            onOk: async () => {
                try {
                    await axios.ajax({
                        url: "/auth/logout"
                    });
                    localStorage.removeItem('session');
                    localStorage.removeItem('urlAfterLogin');
                    this.props.history.push('/login');
                } catch (error) {
                    modal.destroy();
                }
            }
        });
    }

    render() {
        const { userName, sysTime, showReturn, showModal } = this.state;
        const operateMenu = (
            <Menu onClick={this.clickUserSetting}>
                <Menu.Item>
                    <EditOutlined />修改密码
                </Menu.Item>
                <Menu.Item>
                    <LogoutOutlined />退出登录
                </Menu.Item>
            </Menu>
        );

        return <div>
            <Row className="bg-white p-2">
                <Col span={6} className="d-flex">
                    {!!showReturn && <Button
                        type="text"
                        icon={<LeftOutlined />}
                        onClick={() => window.history.go(-1)}
                    />}
                    <div className="h5 text-primary pl-1 h-100 d-flex align-items-center">
                        {this.props.menuName}
                    </div>
                </Col>
                <Col span={18} className="text-right">
                    <span className="mr-2">{sysTime}</span>
                    <span>欢迎</span>
                    <Dropdown
                        overlay={operateMenu}
                        placement="bottomRight"
                    >
                        <Button type="text">
                            {userName}<DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Modal
                title='修改密码'
                visible={showModal}
                onOk={this.confirmModal}
                onCancel={this.cancelModal}
            >
                <Form ref={this.modalForm}>
                    <Form.Item
                        key="oldPassword"
                        name="oldPassword"
                        label="旧密码"
                        rules={[
                            {
                                required: true,
                                message: '旧密码不能为空'
                            }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        key="newPassword"
                        name="newPassword"
                        label="新密码"
                        rules={[
                            {
                                required: true,
                                message: '新密码不能为空'
                            }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    }
}

export default withRouter(Header);