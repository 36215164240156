import React from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

export default class IFooter extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center", ...this.props.textStyle }}>
                <div>
                    <a
                        target="blank"
                        href="http://beian.miit.gov.cn"
                        style={{ textDecoration: "none", ...this.props.textStyle }}
                    >
                        浙ICP备14025016号-2
                    </a>
                    <span>&copy;杭州嘉衡健康科技有限公司&nbsp;&nbsp;</span>
                    <a
                        target="blank"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010974"
                        style={{ textDecoration: "none", ...this.props.textStyle }}
                    >
                        <img src="/assets/record.png" alt="浙公网安备" />
                        <span>浙公网安备 33010802010974号</span>
                    </a>
                </div>
                <div>
                    <span>
                        <MailOutlined />&nbsp;services@jiahenghealth.com&nbsp;&nbsp;
                    </span>
                    <span>
                        <PhoneOutlined />&nbsp;0571-88005270
                    </span>
                </div>
            </div>
        );
    }
}